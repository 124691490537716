<template>
  <div :class="$style.root">
    <VRow dense>
      <VCol cols="12" md="3">
        <div :class="$style.title">{{ label }}</div>
        <div v-if="!!hint" :class="[$style.hint, 'text--gray']" v-html="hint" />
      </VCol>
      <VCol cols="12" md="4">
        <template v-if="type === 'BOOLEAN_WITH_COMMENT'">
          <VRadioGroup v-model="values['BOOL'].value"  row dense class="mt-0" hideDetails="auto">
            <VRadio v-for="{value, text} in options" :label="text" :value="value" :key="value + label" />
          </VRadioGroup>
          <template v-if="values['BOOL'].value === valueForComment">
            <div class="pt-3">
              <VTextarea v-model="values['TEXT'].value" :counter="counter"  :rules="rules" placeholder="Введите комментарий" outlined dense auto-grow rows="1" hideDetails="auto" />
            </div>
          </template>
        </template>
        <template v-else-if="type === 'TEXT'">
          <VTextarea v-model="values['TEXT'].value" :counter="counter"  :rules="rules" placeholder="Введите ответ" outlined dense auto-grow rows="1" hideDetails="auto" />
        </template>
        <template v-else-if="type === 'MARK_WITH_COMMENT'">
          <VRadioGroup v-model="values['MARK'].value" :rules="rules"  row dense class="mt-0" hideDetails="auto">
            <VRadio v-for="index in 5" :label="`${index}`" :value="index" :key="'mark' + index" />
          </VRadioGroup>
          <div class="pt-3">
            <VTextarea v-model="values['TEXT'].value" :counter="counter" :rules="markCommentRules(values['MARK'].value)" placeholder="Введите комментарий" outlined dense auto-grow rows="1" hideDetails="auto" />
          </div>
        </template>
      </VCol>
    </VRow>
  </div>
</template>

<script>
export default {
  name: 'SurveyValues',
  props: {
    label: { type: String },
    hint: { type: String },
    values: { type: Object },
    type: { type: String },
    rules: { type: Array },
    options: { type: Array },
    counter: { default: 300 },
    valueForComment: { type: String },
  },
  methods: {
    markCommentRules(markValue) {
      if (markValue !== 1) return [];
      return this.rules;
    }
  }
}
</script>

<style module lang="scss">
.root {

}
.title {
  font-weight: bold;
  font-size: 14px;
  color: #212121;
}
.hint {
  font-style: italic;
  font-size: 13px;
}
</style>
