<template>
  <div :class="$style.root">
    <h1 v-if="$route.meta.title" class="mb-4">{{$route.meta.title}} <template v-if="id">#{{id}}</template></h1>
    <div v-if="isReviewer || isSpectator" class="mb-4 clearfix">
      <VBtn depressed @click="$router.push({ name: 'pkosmr/SurveyListView' })">
        <VIcon>mdi-arrow-left</VIcon>
        К списку опросов
      </VBtn>
    </div>
    <div class="mb-4 clearfix">
      <VBtn depressed @click="$router.push({ name: 'pkosmr/CounterpartyListView' })">
        <VIcon>mdi-arrow-left</VIcon>
        К списку заявок
      </VBtn>
    </div>
    <div :class="$style.inner">
      <SurveyDetail v-if="surveyForm" :id="id" :values="surveyForm.questions || {}" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ROLES } from '@/store/user/enums';
import SurveyDetail from '@/components/pkosmr/SurveyDetail/SurveyDetail.vue';
export default {
  name: 'SurveyDetailView',
  components: {
    SurveyDetail
  },
  props: {
    id: { type: String },
  },
  computed: {
    ...mapGetters({
      pending: 'pkosmr/pending',
      hasRole: 'user/hasRole',
      surveyForm: 'pkosmr/surveyForm',
    }),
    isReviewer() {
      return this.hasRole([ROLES.REVIEWER, ROLES.FIRST_REVIEWER, ROLES.SECOND_REVIEWER, ROLES.LAWYER, ROLES.APPROVER], 'pkosmr');
    },
    isSpectator() {
      return this.hasRole(ROLES.SPECTATOR, 'pkosmr');
    },
    isContragent() {
      return this.hasRole(ROLES.CONTRAGENT, 'pkosmr');
    },
  },
  methods: {
    ...mapActions({
      fetchSurveyForm: 'pkosmr/fetchSurveyForm',
    }),
  },
  watch: {
    id: {
      immediate: true,
      handler(id) {
        this.fetchSurveyForm({ id });
      }
    }
  }
}
</script>

<style module lang="scss">
.root {
  $header-bg: #efefef;
  $border-color: #dee2e6;
  :global(.v-expansion-panel) {
    border: 1px solid $border-color;
    margin-bottom: -1px;
  }
  .inner {
    position: relative;
  }
}
</style>
